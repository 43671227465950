import * as React from "react"
import * as cn from "classnames"
import {Player} from 'video-react'
import poster from "../../static/poster.jpg"
import video from "../../static/Showreel.mp4"
import Layout from "../components/layout"
import {Carousel} from 'react-responsive-carousel'
import { Helmet } from "react-helmet"

export default class IndexPage extends React.Component {
    state = {
        animation: false,
        modalVisible: false,
        previewUrl: "",
        isMobile: false
    }

    scrollTo(element) {
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: element.offsetTop - 50
        })
    }

    scrollDown() {
        this.scrollTo(document.getElementById("tools"))
    }

    isTop(element) {
        return element.getBoundingClientRect().top <= window.innerHeight;
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling)
        const isMobile = this.isMobile()
        this.setState({isMobile: isMobile})
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
        this.setState({animation: false})
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('about')
        if (this.isTop(wrappedElement)) {
            this.setState({animation: true})
            document.removeEventListener('scroll', this.trackScrolling)
        }
    }

    isMobile() {
        let userAgent = navigator.userAgent || navigator.vendor || window['opera']
        if ((/windows phone/i.test(userAgent)) && window.innerWidth < 770) {
            return true
        }

        if ((/android/i.test(userAgent)) && window.innerWidth < 770) {
            return true
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if ((/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) && window.innerWidth < 770) {
            return true
        }

        if (window.innerWidth < 770) {
            return true
        }

        return false
    }

    render() {
        return (
            <Layout classname={this.state.modalVisible && "overflow"}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Koyot Design</title>
                    <meta name="description" content="Grzegorz Kokot - Portfolio"/>
                    <meta name="theme-color" content="#ffffff" />
                    <meta property="og:title" content="Koyot Design"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://koyot-design.pl/"/>
                    <meta property="og:image" content="https://koyot-design.pl/images/koyot-design.png"/>
                    <meta property="og:description" content="Grzegorz Kokot - Portfolio"/>
                </Helmet>
                <main>
                    <div className="container">
                        <section className="home">
                            <div className="home__content">
                                <div className="text">
                                    <h4>koyot</h4>
                                    <h1>Grzegorz Kokot</h1>
                                    <h2>Grafika i animacja</h2>
                                    <p>Cześć,

                                        fajnie, że jesteś.

                                        Scrolluj śmiało w dół, nie ma tam już więcej moich zdjęć, a trochę konkretów:

                                        czym się zajmuję, z kim do tej pory współpracowałem, jakich narzędzi używam.

                                        Znajdziesz też przykłady moich projektów i kilka słów o mnie.

                                        ENJOY!</p>
                                </div>
                                <img src="images/photo.png" alt=""/>
                            </div>
                            <span className="scroll-down" onClick={() => this.scrollDown()}>
                                <i className="icon-next"></i>
                                <i className="icon-circle"></i>
                            </span>
                        </section>
                        <section id="tools" className="tools">
                            <h3>My Tools</h3>
                            <h2>Moje narzędzia</h2>
                            <div className="row">
                                <div className="item">
                                    <div className="item__icon item__icon--ps">
                                        <span>Ps</span>
                                    </div>
                                    <h5>Photoshop</h5>
                                </div>
                                <div className="item">
                                    <div className="item__icon item__icon--ai">
                                        <span>Ai</span>
                                    </div>
                                    <h5>Illustrator</h5>
                                </div>
                                <div className="item">
                                    <div className="item__icon item__icon--id">
                                        <span>Id</span>
                                    </div>
                                    <h5>InDESIGN</h5>
                                </div>
                                <div className="item">
                                    <div className="item__icon item__icon--ae">
                                        <span>Ae</span>
                                    </div>
                                    <h5>After Effects</h5>
                                </div>
                                <div className="item">
                                    <div className="item__icon item__icon--xd">
                                        <span>Xd</span>
                                    </div>
                                    <h5>Adobe XD</h5>
                                </div>
                                <div className="item">
                                    <div className="item__icon item__icon--pr">
                                        <span>Pr</span>
                                    </div>
                                    <h5>PREMIERE</h5>
                                </div>
                            </div>
                        </section>
                        <section className="gallery">
                            <h3>Portfolio</h3>
                            <h2>Grafika, dtp i ux</h2>
                            {!this.state.isMobile &&
                            <div className="row">
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "brands.jpg"})}>
                                    <img src="images/miniaturka1.jpg" alt=""/>
                                    <div className="text">
                                        Brand<br/> Management
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "2.jpg"})}>
                                    <img src="images/miniaturka2.jpg" alt=""/>
                                    <div className="text">
                                        Info<br/> graphics
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "3.jpg"})}>
                                    <img src="images/miniaturka3.jpg" alt=""/>
                                    <div className="text">
                                        Social<br/> Media
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "4.jpg"})}>
                                    <img src="images/miniaturka4.jpg" alt=""/>
                                    <div className="text">
                                        Presentations
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "5.jpg"})}>
                                    <img src="images/miniaturka5.jpg" alt=""/>
                                    <div className="text">
                                        Web<br/> design
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "6.jpg"})}>
                                    <img src="images/miniaturka6.jpg" alt=""/>
                                    <div className="text">
                                        Posters
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.isMobile &&
                            <Carousel
                                showArrows={false}
                                infiniteLoop={false}
                                autoPlay={false}
                                showIndicators={true}
                                showStatus={false}
                                showThumbs={false}
                            >
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "1.jpg"})}>
                                    <img src="images/miniaturka1.jpg" alt=""/>
                                    <div className="text">
                                        Brand<br/> Management
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "2.jpg"})}>
                                    <img src="images/miniaturka2.jpg" alt=""/>
                                    <div className="text">
                                        Info<br/> graphics
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "3.jpg"})}>
                                    <img src="images/miniaturka3.jpg" alt=""/>
                                    <div className="text">
                                        Social<br/> Media
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "4.jpg"})}>
                                    <img src="images/miniaturka4.jpg" alt=""/>
                                    <div className="text">
                                        Presentations
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "5.jpg"})}>
                                    <img src="images/miniaturka5.jpg" alt=""/>
                                    <div className="text">
                                        Web<br/> design
                                    </div>
                                </div>
                                <div className="item"
                                     onClick={() => this.setState({modalVisible: true, previewUrl: "6.jpg"})}>
                                    <img src="images/miniaturka6.jpg" alt=""/>
                                    <div className="text">
                                        Posters
                                    </div>
                                </div>
                            </Carousel>
                            }
                        </section>
                        <section className="video">
                            <h3>Showreel</h3>
                            <h2>Motion design</h2>
                            <div className="video__content">
                                <Player poster={poster}>
                                    <source src={video}/>
                                </Player>
                            </div>
                        </section>
                        <section id="about" className="about">
                            <h3>About me</h3>
                            <h2>O mnie</h2>
                            <div className="about__content">
                                <div className="left">
                                    <p>
                                        Pracuję jako grafik od 8 lat. Codziennie poznaję nowe narzędzia i techniki,
                                        które wykorzystuję w swoich projektach.

                                        Ukończyłem dziennikarstwo ze specjalizacją fotografia medialna na Uniwersytecie
                                        Gdańskim, po czym udałem się na podyplomówkę UX na SWPS.

                                        Aktualnie dużą przyjemność sprawia mi tworzenie animacji, ale też chętnie
                                        zaprojektuję stronę i stworzę dla Ciebie grafiki socialowe.</p>

                                    <p>Lubię uczyć się nowych rzeczy. Szanuję Youtube za tutoriale, Orbitowskiego za
                                        “Inną duszę” no i Reddit za gify z kotkami.

                                        Wolny czas najchętniej spędzałbym z padem i PS5, ale mam żonę, więc to tak nie
                                        działa (smutnazaba.jpg). Nie pogardzę też wypadem na piwko z&nbsp;planszówką,
                                        czy impro w Klubie Komediowym. Sernik z rodzynkami. Pomidorowa z&nbsp;makaronem.
                                        Mam nadzieję, że się dogadamy.
                                    </p>
                                    <div className="spotify">
                                        <a href="https://open.spotify.com/playlist/76DdqEa28tWwuSmLL37Nva?si=Tgj7SARTS4KUDtQKsEAvhw"
                                           target="_blank">
                                            <i className="icon-spotify"></i>
                                            Moja playlista na Spotify
                                        </a>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="icons">
                                        <div className="item">
                                            <i className="icon-city"></i>
                                            <span className="line" data-line="1">
                                                <span className={cn(this.state.animation && "active")}></span>
                                            </span>
                                            <i className="icon-travel"></i>
                                        </div>
                                        <div className="item">
                                            <i className="icon-xbox"></i>
                                            <span className="line" data-line="2">
                                                <span className={cn(this.state.animation && "active")}></span>
                                            </span>
                                            <i className="icon-pc"></i>
                                        </div>
                                        <div className="item">
                                            <i className="icon-television"></i>
                                            <span className="line" data-line="3">
                                                <span className={cn(this.state.animation && "active")}></span>
                                            </span>
                                            <i className="icon-book"></i>
                                        </div>
                                        <div className="item">
                                            <i className="icon-cat"></i>
                                            <span className="line" data-line="4">
                                                <span className={cn(this.state.animation && "active")}></span>
                                            </span>
                                            <i className="icon-dog"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="brands">
                            <h3>Logos</h3>
                            <h2>Pracowałem dla</h2>
                            {!this.state.isMobile &&
                            <div className="row">
                                <div className="item">
                                    <img src="images/3m_black.png" className="h-1" alt=""/>
                                    <div className="color">
                                        <img src="images/3m.png" className="h-1" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/bahlsen_black.png" className="h-2" alt=""/>
                                    <div className="color">
                                        <img src="images/bahlsen.png" className="h-2" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/dr-irena-eris_black.png" className="h-3" alt=""/>
                                    <div className="color">
                                        <img src="images/dr-irena-eris.png" className="h-3" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/early-stage_black.png" className="h-1" alt=""/>
                                    <div className="color">
                                        <img src="/images/early-stage.png" className="h-1" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/facebook_black.png" className="h-4" alt=""/>
                                    <div className="color">
                                        <img src="images/facebook.png" className="h-4" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/hill_black.png" className="h-5" alt=""/>
                                    <div className="color">
                                        <img src="images/hill.png" className="h-5" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/komatsu_black.png" className="h-5" alt=""/>
                                    <div className="color">
                                        <img src="images/komatsu.png" className="h-5" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/blabla-black.png" className="h-4" alt=""/>
                                    <div className="color">
                                        <img src="images/blabla.png" className="h-4" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/max_black.png" className="h-6" alt=""/>
                                    <div className="color">
                                        <img src="images/max.png" className="h-6" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/lublin_black.png" className="h-7" alt=""/>
                                    <div className="color">
                                        <img src="images/lublin.png" className="h-7" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/nestle_black.png" className="h-7" alt=""/>
                                    <div className="color">
                                        <img src="images/nestle.png" className="h-7" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/facebook-przestrzen_black.png" className="h-8" alt=""/>
                                    <div className="color">
                                        <img src="images/facebook-przestrzen.png" className="h-8" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/sokolka_black.png" className="h-1" alt=""/>
                                    <div className="color">
                                        <img src="images/sokolka.png" className="h-1" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/cl-black.png" className="h-2" alt=""/>
                                    <div className="color">
                                        <img src="images/cl.png" className="h-2" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/upc_black.png" className="h-9" alt=""/>
                                    <div className="color">
                                        <img src="images/upc.png" className="h-9" alt=""/>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src="images/ups-zdrowie_black.png" className="h-9" alt=""/>
                                    <div className="color">
                                        <img src="images/ups-zdrowie.png" className="h-9" alt=""/>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.isMobile &&
                            <Carousel
                                showArrows={false}
                                infiniteLoop={false}
                                autoPlay={true}
                                showIndicators={false}
                                showStatus={false}
                                showThumbs={false}
                            >
                                <div className="item">
                                    <img src="images/3m_black.png" className="h-1" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/bahlsen_black.png" className="h-2" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/dr-irena-eris_black.png" className="h-3" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/early-stage_black.png" className="h-1" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/facebook_black.png" className="h-4" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/hill_black.png" className="h-5" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/komatsu_black.png" className="h-5" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/luno_black.png" className="h-4" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/max_black.png" className="h-6" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/lublin_black.png" className="h-7" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/nestle_black.png" className="h-7" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/facebook-przestrzen_black.png" className="h-8" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/sokolka_black.png" className="h-1" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/samlon_black.png" className="h-3" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/upc_black.png" className="h-9" alt=""/>
                                </div>
                                <div className="item">
                                    <img src="images/ups-zdrowie_black.png" className="h-9" alt=""/>
                                </div>
                            </Carousel>
                            }
                        </section>
                    </div>
                </main>
                {/*<section className="contact">*/}
                {/*    <div className="container">*/}
                {/*        <h6>Kontakt</h6>*/}
                {/*        <h3>Contact</h3>*/}
                {/*        <h2>Kontakt</h2>*/}
                {/*        <div className="form">*/}
                {/*            <form role="form" action="" method="post">*/}
                {/*                <div className="form__row">*/}
                {/*                    <input type="text" placeholder="Imię i nazwisko"/>*/}
                {/*                    <i className="icon-employee"></i>*/}
                {/*                </div>*/}
                {/*                <div className="form__row">*/}
                {/*                    <input type="phone" placeholder="Telefon"/>*/}
                {/*                    <i className="icon-phone"></i>*/}
                {/*                </div>*/}
                {/*                <div className="form__row">*/}
                {/*                    <textarea placeholder="Wiadomość"></textarea>*/}
                {/*                    <i className="icon-chat-box"></i>*/}
                {/*                </div>*/}
                {/*                <div className="form__row right">*/}
                {/*                    <button type="submit" id="submit" name="submit">Wyślij</button>*/}
                {/*                </div>*/}
                {/*            </form>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <div className={cn("modal", this.state.modalVisible && "active")}>
                    <span className="modal__close"
                          onClick={() => this.setState({modalVisible: false, previewPath: ""})}>
                        <i className="icon-close"></i>
                    </span>
                    <div className="modal__content">
                        <img src={this.state.previewUrl === "" ? "images/1.jpg" : "images/" + this.state.previewUrl}
                             alt=""/>
                    </div>
                </div>
            </Layout>
        )
    }
}
